import React, { useState } from "react"
import { graphql } from "gatsby"
import Modal from "../components/modal"
import ImageModal from "../components/imageModal"
import SponsorshipForm from "../components/sponsorshipForm"
import PastAwardWinnersCard from "../components/pastAwardWinnersCard"
import PastEventsSelect from "../components/pastEventsSelect"

import AndreHaddad from "../images/past_events/people/Andre-Haddad_Edit.jpg"
import CoryGardner from "../images/past_events/people/Cory-Gardner_Edit.jpg"
import DavidHall from "../images/past_events/people/David-Hall_Edit.jpg"
import DebbieStabenow from "../images/past_events/people/Debbie-Stabenow_Edit.jpg"
import DeborahHersman from "../images/past_events/people/Deborah-Hersman.jpg"
import EdzardOverbeek from "../images/past_events/people/Edzard-Overbeek_Edit.jpg"
import ElaineChao from "../images/past_events/people/Elaine_Chao.jpg"
import FrankPallone from "../images/past_events/people/Frank-Pallone_Edit.jpg"
import GregWalden from "../images/past_events/people/Greg-Walden_Edit.jpg"
import KareemYusuf from "../images/past_events/people/Kareem_Yusuf_Edit.png"
import KellyHancock from "../images/past_events/people/Kelly-Hancock_Edit.jpg"
import PeteRicketts from "../images/past_events/people/Pete-Ricketts_Edit.jpg"
import RobbieDiamond from "../images/past_events/people/Robbie-Diamond_Edit.jpg"
import YuvalWeisglass from "../images/past_events/people/Yuval-Weisglass_Edit.jpg"

import Event1 from "../images/past_events/event_photos/autos2050-2018-event-1.jpeg"
import Event2 from "../images/past_events/event_photos/autos2050-2018-event-2.jpeg"
import Event3 from "../images/past_events/event_photos/autos2050-2018-event-3.jpeg"
import Event4 from "../images/past_events/event_photos/autos2050-2018-event-5.jpeg"
import Event5 from "../images/past_events/event_photos/autos2050-2018-event-6.jpeg"
import Event6 from "../images/past_events/event_photos/autos2050-2018-event-7.jpeg"
import Event7 from "../images/past_events/event_photos/autos2050-2018-event-8.jpeg"
import Event8 from "../images/past_events/event_photos/autos2050-2018-event-9.jpeg"
import Event9 from "../images/past_events/event_photos/autos2050-2018-event-12.jpeg"
import Event10 from "../images/past_events/event_photos/autos2050-2018-event-13.jpeg"
import Event11 from "../images/past_events/event_photos/autos2050-2018-event-14.jpeg"
import Event12 from "../images/past_events/event_photos/autos2050-2018-event-16.jpeg"
import Event13 from "../images/past_events/event_photos/autos2050-2018-event-17.jpeg"
import Event14 from "../images/past_events/event_photos/autos2050-2018-event-18.jpeg"
import Event15 from "../images/past_events/event_photos/mitch-bainwol.jpeg"

import Layout from "../components/layout"
import Seo from "../components/seo"
import BeFeaturedBanner from "../components/beFeaturedBanner"

import { AnimatePresence, motion } from "framer-motion"

function ProgramsPage({ data }) {
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedImg, setSelectedImg] = useState(null)
  const sponsors = (data || {}).sponsors
  const pdf = (data || {}).pdf
  // console.log(sponsors)
  return (
    <Layout title="programs">
      <Seo title="Past Events" />
      <AnimatePresence exitBeforeEnter>
        {modalOpen && (
          <Modal open={modalOpen} onClose={() => setModalOpen(false)} key="Sponsorship Form Past Events">
            <SponsorshipForm />
          </Modal>
        )}
      </AnimatePresence>
      <AnimatePresence exitBeforeEnter>
        {selectedImg && (
          <ImageModal
            selectedImg={selectedImg}
            setSelectedImg={setSelectedImg}
            key="Image Modal"
          />
        )}
      </AnimatePresence>
      <PastEventsSelect activeEvent={`summit2019`}/>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <section className="flex flex-col items-center justify-center py-32 w-full text-center bg-past-events-page-header-colorized bg-cover bg-center bg-no-repeat">
          <h1 className="text-white text-4xl italic font-bold lg:text-6xl xl:text-7xl">
            PAST EVENTS
          </h1>
        </section>
        <section className="mx-auto py-8 w-full text-center space-y-8 lg:py-16">
          <h2 className="text-secondary-300 text-2xl font-bold lg:text-4xl">
            2019 Event Highlights
          </h2>
          <div className="grid place-items-center">
            <div className="past-events-columns grid gap-2 place-items-center text-center">
              <button onClick={() => setSelectedImg(Event1)}>
                <img
                  className="mx-auto w-24 md:w-32"
                  src={Event1}
                  alt="Event 1"
                />
              </button>
              <button onClick={() => setSelectedImg(Event2)}>
                <img
                  className="mx-auto w-24 md:w-32"
                  src={Event2}
                  alt="Event 2"
                />
              </button>
              <button onClick={() => setSelectedImg(Event3)}>
                <img
                  className="mx-auto w-24 md:w-32"
                  src={Event3}
                  alt="Event 3"
                />
              </button>
              <button onClick={() => setSelectedImg(Event4)}>
                <img
                  className="mx-auto w-24 md:w-32"
                  src={Event4}
                  alt="Event 4"
                />
              </button>
              <button onClick={() => setSelectedImg(Event5)}>
                <img
                  className="mx-auto w-24 md:w-32"
                  src={Event5}
                  alt="Event 5"
                />
              </button>
              <button onClick={() => setSelectedImg(Event6)}>
                <img
                  className="mx-auto w-24 md:w-32"
                  src={Event6}
                  alt="Event 6"
                />
              </button>
              <button onClick={() => setSelectedImg(Event7)}>
                <img
                  className="mx-auto w-24 md:w-32"
                  src={Event7}
                  alt="Event 7"
                />
              </button>
              <button onClick={() => setSelectedImg(Event8)}>
                <img
                  className="mx-auto w-24 md:w-32"
                  src={Event8}
                  alt="Event 8"
                />
              </button>
              <button onClick={() => setSelectedImg(Event9)}>
                <img
                  className="mx-auto w-24 md:w-32"
                  src={Event9}
                  alt="Event 9"
                />
              </button>
              <button onClick={() => setSelectedImg(Event10)}>
                <img
                  className="mx-auto w-24 md:w-32"
                  src={Event10}
                  alt="Event 10"
                />
              </button>
              <button onClick={() => setSelectedImg(Event11)}>
                <img
                  className="mx-auto w-24 md:w-32"
                  src={Event11}
                  alt="Event 11"
                />
              </button>
              <button onClick={() => setSelectedImg(Event12)}>
                <img
                  className="mx-auto w-24 md:w-32"
                  src={Event12}
                  alt="Event 12"
                />
              </button>
              <button onClick={() => setSelectedImg(Event13)}>
                <img
                  className="mx-auto w-24 md:w-32"
                  src={Event13}
                  alt="Event 13"
                />
              </button>
              <button onClick={() => setSelectedImg(Event14)}>
                <img
                  className="mx-auto w-24 md:w-32"
                  src={Event14}
                  alt="Event 14"
                />
              </button>
              <button onClick={() => setSelectedImg(Event15)}>
                <img
                  className="mx-auto w-24 md:w-32"
                  src={Event15}
                  alt="Event 15"
                />
              </button>
            </div>
          </div>
        </section>
        <section className="flex items-center justify-center py-8 w-full lg:pb-16 lg:pt-8">
          <div className="flex flex-col justify-center mx-auto my-8 w-10/12 max-w-3xl text-left space-y-8 lg:flex-row lg:space-x-8 lg:space-y-0">
            <div>
              <img
                className="w-48 h-48 rounded-full object-cover"
                src={ElaineChao}
                alt="ElaineChao"
              />
            </div>
            <div className="space-y-4">
              <p className="text-secondary-200 text-xs font-bold lg:text-sm">
                KEYNOTE SPEAKER
              </p>
              <h3 className="lg:text-2l max-w-lg text-gray-500 text-xl font-bold">
                U.S. Secretary of Transportation Elaine L. Chao
              </h3>
              <p className="max-w-lg text-gray-500 text-sm lg:text-base">
                Autos2050® is very excited to announce that the 18th U.S.
                Secretary of Transportation, Elaine L. Chao will present a
                keynote address at the Driving Innovation Awards Dinner on April
                2, 2019.
              </p>
              <p className="max-w-lg text-gray-500 text-sm lg:text-base">
                “Thanks to Secretary Chao, the U.S. Department of Transportation
                (DOT) has provided strong leadership in spurring the development
                of innovative safety technologies, including self-driving
                vehicles,” said Dave Schwietert Interim President & CEO of the
                Auto Alliance. “These innovations promise profound societal
                benefits such as greater personal freedom and independence for
                the elderly, people with disabilities and others seeking
                improved access to mobility.”
              </p>
              <p className="max-w-lg text-gray-500 text-sm lg:text-base">
                In 2018, DOT issued its updated Autonomous Vehicle (AV) Policy
                Guidance (3.0) that provided greater clarity to automakers and
                their technology partners working on promising systems to
                enhance roadway safety, expand access to mobility, and ensure
                the U.S. continues to lead the safe development, testing, and
                deployment of AVs. In addition, DOT’s recently announced AV
                Pilot Program will help advance the testing and deployment of AV
                technologies so public safety remains a top priority.
              </p>
            </div>
          </div>
        </section>
        <section className="py-16 w-full bg-gray-200 lg:py-24">
          <div className="flex flex-col items-center justify-center mx-auto w-10/12 max-w-3xl text-center space-y-8">
            <h2 className="text-gray-500 text-2xl font-bold lg:text-5xl">
              Past Award Winners
            </h2>
            <h3 className="text-gray-500 text-lg lg:text-xl">
              Autos2050 is proud to announce the 2019 award winners in the
              categories of Ingenuity, Impace and Leadership.
            </h3>
            <a href={pdf.file.asset.url}>
              <button
                className="border-2
							font-bold
							text-black
							border-black
							py-2
							px-4
							rounded-full
							min-w-150
							transition
							focus:outline-none
							hover:bg-black
							hover:text-white"
              >
                READ FULL PRESS RELEASE
              </button>
            </a>
            <p className="text-gray-500 text-sm">
              These leaders are recognized for shaping the future of
              auto-mobility. Their creativity and uniqueness demonstrate the
              exceptional contributions to improvements in technologies,
              society, the environment, public policy, and the economy.
            </p>
          </div>
        </section>
        <section className="py-16 w-full text-center space-y-8 lg:py-24 lg:space-y-16">
          <div className="space-y-8">
            <h3 className="text-gold text-xl font-bold lg:text-2xl">
              2019 Ingenuity Award Winners
            </h3>
            <div className="flex flex-wrap items-start justify-center mx-auto w-10/12">
              <PastAwardWinnersCard
                image={DavidHall}
                name="David Hall"
                title="Founder & CEO, Velodyne"
              />
              <PastAwardWinnersCard
                image={AndreHaddad}
                name="Andre Haddad"
                title="CEO, Turo"
              />
              <PastAwardWinnersCard
                image={EdzardOverbeek}
                name="Edzard Overbeek"
                title="CEO, HERE Technologies"
              />
              <PastAwardWinnersCard
                image={YuvalWeisglass}
                name="Yuval Weisglass"
                title="Vice President, Automotive Cyber Security HARMAN"
              />
              <PastAwardWinnersCard
                image={KareemYusuf}
                name="Kareem Yusuf Ph.D"
                title="General Manager, Watson IoT"
              />
            </div>
          </div>
          <div className="space-y-8">
            <h3 className="text-gold text-xl font-bold lg:text-2xl">
              2019 Impact Award Winners
            </h3>
            <div className="flex flex-wrap items-start justify-center mx-auto w-10/12">
              <PastAwardWinnersCard
                image={RobbieDiamond}
                name="Robbie Diamond"
                title="Founder, President and CEO, Securing America's Future Energy (SAFE)"
              />
              <PastAwardWinnersCard
                image={DeborahHersman}
                name="Deborah Hersman"
                title="Former President and CEO, National Safety Council (NSC)"
              />
            </div>
          </div>
          <div className="space-y-8">
            <h3 className="text-gold text-xl font-bold lg:text-2xl">
              2019 Leadership Award Winners
            </h3>
            <div className="flex flex-wrap items-start justify-center mx-auto w-10/12 lg:w-3/4 xl:w-8/12">
              <PastAwardWinnersCard
                image={CoryGardner}
                name="Senator Cory Gardner"
                title="(R-CO)"
              />
              <PastAwardWinnersCard
                image={KellyHancock}
                name="State Senator Kelly"
                title="(R-TX)"
              />
              <PastAwardWinnersCard
                image={FrankPallone}
                name="Rep. Frank Pallone"
                title="(D-NJ)"
              />
              <PastAwardWinnersCard
                image={PeteRicketts}
                name="Governor Pete Ricketts"
                title="(R-NE)"
              />
              <PastAwardWinnersCard
                image={DebbieStabenow}
                name="Senator Debbie Stabenow"
                title="(D-MI)"
              />
              <PastAwardWinnersCard
                image={GregWalden}
                name="Rep. Debbie Stabenow"
                title="(R-OR)"
              />
            </div>
          </div>
        </section>
        <section className="py-16 w-full text-center bg-light-100 lg:py-24">
          <h3 className="mb-8 text-gray-600 text-secondary-300 text-2xl font-bold lg:text-3xl">
            Thank You to Our Past Sponsors
          </h3>
          <div className="grid gap-8 grid-cols-2 2xl:grid-cols-5 place-items-center mx-auto w-10/12 lg:grid-cols-3 lg:w-8/12 xl:grid-cols-4">
            {sponsors &&
              sponsors.edges
                .filter(sponsor =>
                  sponsor.node.year.some(
                    element =>
                      element.value !== new Date().getFullYear().toString()
                  )
                )
                .map(sponsor => (
                  <a
                    href={sponsor.node.company.url}
                    target="_blank"
                    rel="noreferrer"
                    className="transform hover:scale-110 transition"
                  >
                    <img
                      src={sponsor.node.company.logo.asset.url}
                      alt={sponsor.node.company.name}
                      className="px-4 py-2 w-48 h-24 bg-white object-contain object-center"
                    />
                  </a>
                ))}
          </div>
        </section>
        <BeFeaturedBanner />
      </motion.div>
    </Layout>
  )
}

export default ProgramsPage

export const query = graphql`
  {
    sponsors: allSanitySponsor {
      edges {
        node {
          company {
            logo {
              asset {
                url
              }
            }
            url
            name
          }
          year {
            value
          }
          sponsorTypes {
            name
          }
        }
      }
    }
    pdf: sanityPressrelease(
      name: { eq: "Autos2050 Announces Winners of 2019 Awards" }
    ) {
      file {
        asset {
          url
        }
      }
    }
  }
`
