import React from "react"

function PastAwardWinnersCard({ image, name, title }) {
  return (
    <div className="flex text-center justify-center items-center flex-col space-y-4 box-content p-4">
      <img
        className="w-32 h-32 rounded-full object-cover"
        src={image}
        alt="Speaker"
      />
      <div>
        <p className="text-gray-500 font-bold text-sm lg:text-base">{name}</p>
        <p className="text-gray-500 text-sm lg:text-base w-56">{title}</p>
      </div>
    </div>
  )
}

export default PastAwardWinnersCard
