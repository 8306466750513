/**
 * Menu for selecting past events
 */

import React from "react"
import { Link } from "gatsby"

function PastEventsSelect({activeEvent}) {
  return (
    <div className="flex items-center justify-center mt-8 pt-20 pb-6 w-full bg-ochre">
      <ul className="flex items-center justify-center h-full w-full sm:w-3/5 sm:justify-start">
        <li>
          <Link to="/past-events/summit-2021">
            <button className={`mr-4  px-4 py-0.5 rounded-full font-semibold text-sm ${activeEvent === 'summit2021' ? "bg-white text-ochre" : "text-white"}`}>2021 SUMMIT</button>
          </Link>
        </li>
        <li>
          <Link to="/past-events">
            <button className={`mr-4 px-4 py-0.5 rounded-full font-semibold text-sm ${activeEvent === 'summit2019' ? "bg-white text-ochre" : "text-white"}`}>2019 SUMMIT</button>
          </Link>
        </li>
      </ul>
    </div>
  )
}


export default PastEventsSelect
