import React from "react"
import { motion } from "framer-motion"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"

function ImageModal({ selectedImg, setSelectedImg }) {
  const backdrop = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  }

  const modal = {
    hidden: {
      y: 100,
      opacity: 0,
    },
    show: {
      y: 0,
      opacity: 1,
    },
  }

  return (
    <>
      {selectedImg !== null && (
        <motion.div
          variants={backdrop}
          initial="hidden"
          animate="show"
          exit="hidden"
          className="modal-overlay"
          onClick={() => setSelectedImg(null)}
          onKeyDown={() => setSelectedImg(null)}
          role="button"
          tabIndex="0"
        >
          <motion.div
            variants={modal}
            className="modal"
            onClick={e => {
              e.stopPropagation()
            }}
            onKeyDown={e => {
              e.stopPropagation()
            }}
            role="button"
            tabIndex="-1"
          >
            <img src={selectedImg} alt="Selected" />
            <button
              onClick={() => setSelectedImg(null)}
              onKeyDown={() => setSelectedImg(null)}
              className="absolute right-0 top-0 m-4 w-8 h-8 text-black text-2xl bg-white rounded-full focus:outline-none"
              tabIndex="-1"
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </motion.div>
        </motion.div>
      )}
    </>
  )
}

export default ImageModal
